import { SearchIconSelected } from '@components/icons/SearchIconSelected';
import { DatoImage } from '@components/image/DatoImage';
import { SearchBar } from '@components/search-bar/SearchBar';
import config from '@data/config.json';
import { MenuFragment, ResponsiveImageFragment } from '@graphql/generated';
import { AltsType, Locales } from '@model/common';
import { usePathname, useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { SearchIcon } from '..';
import CartToggle from './CartToggle';
import CountrySelector from './CountrySelector';
import { LanguageSelector } from './LanguageSelector';
import { Navbar } from './Navbar';
import { UserToggle } from './UserToggle';

type Props = {
  locale: Locales;
  baseUrl: string;
  showHeader: boolean;
  menu: MenuFragment[];
  logo: ResponsiveImageFragment | string;
  alts?: AltsType;
};

const isSearchBarEnabled = config.configuration.searchBar;

export const Header: React.FC<Props> = ({ locale, baseUrl, showHeader, menu, logo, alts }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [toggleSearchBar, setToggleSearchBar] = useState(false);
  const slugPath = `${baseUrl}/${locale}`;
  return (
    <>
      <header>
        <div className="wrap">
          <div className="nav">
            {showHeader && (
              <div className="nav__menu">
                <Navbar
                  lang={locale}
                  baseUrl={baseUrl}
                  menu={menu}
                  alts={alts}
                  locale={locale}
                  onOpenMenuChange={(isMenuOpened: boolean) => {
                    if (isMenuOpened) {
                      setToggleSearchBar(false);
                    }
                  }}
                />
                {isSearchBarEnabled && (
                  <div
                    className="nav__account__search_icon__left"
                    onClick={() => setToggleSearchBar(!toggleSearchBar)}
                  >
                    {!toggleSearchBar ? (
                      <div className="search_icon__no_active">
                        <SearchIcon className="search__icon" width="27px" height="27px" />
                      </div>
                    ) : (
                      <div className="search_icon__active">
                        <SearchIconSelected
                          className="search__icon__selected"
                          width="27px"
                          height="27px"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="nav__logo">
              <a href={slugPath} aria-label="home">
                {logo && typeof logo == 'string' && <img src={logo} alt="home" />}
                {logo && typeof logo != 'string' && <DatoImage data={logo} />}
              </a>
            </div>
            {showHeader && (
              <div className="nav__account">
                <CountrySelector locale={locale} />
                {isSearchBarEnabled && (
                  <div
                    className="nav__account__search_icon"
                    onClick={() => setToggleSearchBar(!toggleSearchBar)}
                  >
                    {!toggleSearchBar ? (
                      <div className="search_icon__no_active">
                        <SearchIcon className="search__icon" width="27px" height="27px" />
                      </div>
                    ) : (
                      <div className="search_icon__active">
                        <SearchIconSelected
                          className="search__icon__selected"
                          width="27px"
                          height="27px"
                        />
                      </div>
                    )}
                  </div>
                )}
                <UserToggle locale={locale} baseUrl={baseUrl} />
                <LanguageSelector locale={locale} baseUrl={baseUrl} alts={alts} />
                <CartToggle locale={locale} baseUrl={baseUrl} />
              </div>
            )}
          </div>
        </div>
      </header>
      {(pathname.indexOf('account') > 0 || pathname.indexOf('checkout') > 0) && (
        <div className="header__border" />
      )}
      {toggleSearchBar && isSearchBarEnabled && (
        <SearchBar onClose={() => setToggleSearchBar(!toggleSearchBar)} />
      )}
    </>
  );
};
