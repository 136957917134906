import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import t from '@lib/locales';
import { Locales } from '@model/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  client: AppCommerceLayerClient;
  locale: Locales;
  baseUrl: string;
  children?: any;
  showTitle?: boolean;
};

const RecoverPassword: React.FC<Props> = ({
  client,
  locale,
  baseUrl,
  children,
  showTitle = true,
}) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: true });
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setUi({ errorMessage: null, loading: false });
  }, []);

  const getResetPasswordUrl = () => {
    return `${process.env.HOST}${baseUrl}/${locale}/reset-password`;
  };

  const resetPassword = async ({ email }: any) => {
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      const url = getResetPasswordUrl();
      //@ts-expect-error TODO
      const result = await client.resetPassword({
        email,
        url,
        language: locale,
      });
      setSuccess(true);
      // console.log(result);
    } catch (error) {
      errorMessage = t(locale, 'step_error');
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  const onSubmit = (data: any) => resetPassword(data);

  return (
    <div className="auth">
      {showTitle && <h4 className="auth__title">{t(locale, 'forgot_password')}</h4>}

      {success && <p>{t(locale, 'forgot_disclaimer')}</p>}

      {!success && (
        <div>
          <p style={{ padding: '2px' }}>{t(locale, 'forgot_disclaimer')}</p>
          {ui.errorMessage && <div className="form__error">{ui.errorMessage}</div>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form__field">
              <input
                type="email"
                placeholder={t(locale, 'email')}
                {...register('email', { required: true })}
                className="form__input"
              />
              {errors.email && <span className="form__error">{t(locale, 'field_required')}</span>}
            </div>
            <div className="form__field">
              {!ui.loading && (
                <button className="button--primary" type="submit">
                  {t(locale, 'send')}
                </button>
              )}
              {ui.loading && <div> {t(locale, 'loading')}</div>}
            </div>
          </form>
          {children}
        </div>
      )}
    </div>
  );
};
export default RecoverPassword;
